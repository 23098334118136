import { graphql } from "gatsby";
import React from "react";
import Helmet from "react-helmet";
import { MarkdownPageQuery } from "../../graphql-types";
import { Intro } from "../components/Intro";
import { Layout } from "../components/Layout";
import { Section } from "../components/section/Section";

export const query = graphql`
  query MarkdownPage($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`;

interface MarkdownPageProps {
  data: MarkdownPageQuery;
}

const MarkdownPage: React.FC<MarkdownPageProps> = props => {
  const { frontmatter, html } = props.data.markdownRemark!;

  return (
    <Layout>
      <Helmet>
        <title>{frontmatter!.title!}</title>
      </Helmet>
      <Intro title={frontmatter!.title!}></Intro>
      <Section>
        <div dangerouslySetInnerHTML={{ __html: html! }}></div>
      </Section>
    </Layout>
  );
};

export default MarkdownPage;
